<template>
  <DynamicView>
    <TableView
      :heading="$t(`pages.analysisAndBenchmark`)"
      :description="$t(`analysisAndBenchmark.fullRights`)"
      v-bind="{ tabs, hasPagination }"
    />
  </DynamicView>
</template>

<script>
import { useStore } from 'vuex'
import { TableView } from '@/components/layout'
import DynamicView from '@/components/layout/DynamicView'
import { useRequest } from '@/composition'
import { objectGroups as objectGroupsApi } from '@/services/api'

export default {
  components: {
    TableView,
    DynamicView,
  },
  data() {
    return {
      assetGroupsCount: 0,
      hasPagination: (this.hasPagination =
        this.$route.path !== '/asset-management/access-request'),
    }
  },
  setup() {
    const store = useStore()

    const organisationIsTestAccount =
      store.getters['auth/organisationIsTestAccount']

    const assetGroupsRequest = useRequest(() => {
      return objectGroupsApi.getList()
    })

    return {
      organisationIsTestAccount,
      assetGroupsRequest,
    }
  },
  provide() {
    return {
    }
  },
  computed: {
    tabs() {
      let tabs = [
        {
          routeName: 'benchmarkObjectGroupManagement',
          label: this.$t('analysisAndBenchmark.objectGroupsTab'),
        },
      ]

      return tabs
    },
  },
  methods: {
    async fetchAssetGroupsCount() {
      if (!this.organisationIsTestAccount) {
        return
      }
      const assetGroupsList = await this.assetGroupsRequest.request()
      this.assetGroupsCount = assetGroupsList.length
    },
  },
  watch: {
    organisationIsTestAccount: {
      handler: function () {
        this.fetchAssetGroupsCount()
      },
      immediate: true,
    },
    $route(value) {
      this.hasPagination = value?.path !== '/asset-management/access-request'
    },
  },
}
</script>
